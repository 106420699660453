// External Dependencies
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Typography, useTheme, Stack } from '@mui/material';

// Internal Dependencies
import { AdBoardInviteDto } from '@keyops-hcp/dtos';
import { CalendarToday } from '@mui/icons-material';
import { EngagementTopBar } from './EngagementCardTopBar';
import { EngagementCard } from './EngagementCard';

export const ActiveAdBoardCard = ({
  adBoardInvite,
}: {
  adBoardInvite: AdBoardInviteDto;
}) => {
  const { t, i18n } = useTranslation();

  const navigate = useNavigate();
  const theme = useTheme();
  const startDate = adBoardInvite.adBoard.startDate
    ? new Date(adBoardInvite.adBoard.startDate).toLocaleDateString(
        i18n.language,
        {
          month: 'short',
          day: 'numeric',
        }
      )
    : undefined;
  const endDate = adBoardInvite.adBoard.endDate
    ? new Date(adBoardInvite.adBoard.endDate).toLocaleDateString(
        i18n.language,
        {
          month: 'short',
          day: 'numeric',
        }
      )
    : undefined;
  return (
    <EngagementCard
      onClick={() => navigate('/adboard-landing/' + adBoardInvite.adBoardId)}
      actionLabel={t('dashboard.activeEngagementsSection.viewDetails')}
    >
      <Stack spacing={1}>
        <EngagementTopBar type="adBoard">
          <Typography fontSize="14px" fontWeight={600}>
            {t('dashboard.activeEngagementsSection.earn')} $
            {adBoardInvite.honorarium}
          </Typography>
          {startDate && (
            <>
              <CalendarToday
                sx={{
                  fontSize: 16,
                  mr: 1,
                  color: theme.palette.keyops.blue.dark,
                }}
              />{' '}
              {startDate} {endDate && <>- {endDate}</>}
            </>
          )}
        </EngagementTopBar>
        <Typography>{adBoardInvite.adBoard.company.name}</Typography>
        <Typography
          variant="body1"
          color={theme.palette.keyops.black.main}
          sx={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: '-webkit-box',
            WebkitLineClamp: 2,
            WebkitBoxOrient: 'vertical',
          }}
        >
          {adBoardInvite.adBoard.title}
        </Typography>
      </Stack>
    </EngagementCard>
  );
};

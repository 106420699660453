import React, { useState } from 'react';
import { Outlet, useNavigate, useParams } from 'react-router';
import { Box, Toolbar } from '@mui/material';
import {
  AdBoardTopBar,
  DiscussionsDrawer,
  SectionsDrawer,
} from '@keyops-hcp/ui-components';

import OutsideDrawer from './OutsideDrawer';
import { ADBOARD_SECTIONS } from '../../utils/routes';
import { getUrl } from '../../utils/functions/common-utils';
import { AdBoardLayoutOutletContext } from './AdBoardLayoutOutletContext';

const AdBoardLayoutWrapper = (): JSX.Element => {
  const navigate = useNavigate();
  const { adBoardId } = useParams();
  const [outsideDrawerOpen, setOutsideDrawerOpen] = useState(false);
  const toggleOutsideDrawer = (newOpen: boolean) => () => {
    setOutsideDrawerOpen(newOpen);
  };

  // Discussions board nav handling
  const [discussionDrawerOpen, setDiscussionDrawerOpen] = useState(false);
  const toggleDiscussionDrawer = () => {
    setDiscussionDrawerOpen((prevOpen) => !prevOpen);
  };

  // Discussion messages handling
  const [displayedDiscussionId, setDisplayedDiscussionId] = useState<
    string | undefined
  >(undefined);

  const setCurrentSectionId = (id: string) => {
    navigate(
      getUrl(ADBOARD_SECTIONS, {
        adBoardId,
        sectionId: id,
      })
    );
  };

  return (
    <Box display={'flex'} flexDirection={'column'} height={'100vh'}>
      <AdBoardTopBar
        toggleOutsideDrawer={toggleOutsideDrawer}
        toggleDiscussionDrawer={toggleDiscussionDrawer}
      />
      <Toolbar />
      <Box
        data-testid="main-wrapper"
        flexGrow={1}
        display={'flex'}
        overflow={'hidden'}
        sx={{ backgroundColor: '#ffffff' }}
      >
        <SectionsDrawer
          setCurrentSectionId={setCurrentSectionId}
          setDiscussionDrawerOpen={setDiscussionDrawerOpen} // needed for discussion section
          setDisplayedDiscussionId={setDisplayedDiscussionId} // needed for discussion section
        />
        <Box
          component="main"
          flexGrow={1}
          overflow="auto"
          px={10}
          pt={4}
          sx={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Outlet
            context={
              { setCurrentSectionId } satisfies AdBoardLayoutOutletContext
            }
          />
        </Box>

        {/* To display all adboard discussions */}
        <DiscussionsDrawer
          displayedDiscussionId={displayedDiscussionId}
          setDisplayedDiscussionId={setDisplayedDiscussionId}
          discussionDrawerOpen={discussionDrawerOpen}
          toggleDiscussionDrawer={toggleDiscussionDrawer}
        />
      </Box>
      <OutsideDrawer
        outsideDrawerOpen={outsideDrawerOpen}
        toggleOutsideDrawer={toggleOutsideDrawer}
      />
    </Box>
  );
};

export default AdBoardLayoutWrapper;

import React, { useState } from 'react';
import { Tabs, Tab, useTheme, useMediaQuery, Box, Stack } from '@mui/material/';
import { UserDto, EngagementData } from '@keyops-hcp/dtos';
import { useTranslation } from 'react-i18next';

import ActiveEngagementsSection from './components/ActiveEngagementsSection';
import ReferralOpportunitiesSection from './components/ReferralOpportunitiesSection';
import { KeyopsHeader3 } from '@keyops-hcp/ui-components';

type EngagementSectionProps = {
  userData: UserDto;
  openReferral: (engagementId: number) => void;
  engagementData: EngagementData;
};

const EngagementSection = ({
  userData,
  openReferral,
  engagementData,
}: EngagementSectionProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { t } = useTranslation();

  const [tabValue, setTabValue] = useState(0);

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  if (isMobile) {
    return (
      <>
        <Tabs
          value={tabValue}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          sx={{
            mt: 3,
            borderBottom: '1px solid gray',
            '& .MuiTab-root': {
              paddingLeft: 1.1,
              paddingRight: 1.1,
            },
          }}
        >
          <Tab
            sx={{ textTransform: 'none' }}
            label={t('dashboard.activeEngagementsSection.title')}
          />
          <Tab
            sx={{ textTransform: 'none' }}
            label={t('dashboard.referralOpportunitiesSection.title')}
          />
        </Tabs>
        <Box mt={2}>
          {tabValue === 0 && (
            <ActiveEngagementsSection
              userData={userData}
              engagementData={engagementData}
            />
          )}
          {tabValue === 1 && (
            <ReferralOpportunitiesSection
              userData={userData}
              openReferral={openReferral}
              engagementData={engagementData}
            />
          )}
        </Box>
      </>
    );
  }

  return (
    <>
      {/* Active Engagements Section */}
      <Box>
        <Stack alignItems="center" direction="row" mb={3}>
          <KeyopsHeader3>
            {t('dashboard.activeEngagementsSection.title')}
          </KeyopsHeader3>
        </Stack>
        <ActiveEngagementsSection
          userData={userData}
          engagementData={engagementData}
        />
      </Box>
      {/* Referral Opportunities Section*/}
      <Box mt={4}>
        <Stack alignItems="center" direction="row" mb={3}>
          <KeyopsHeader3>
            {t('dashboard.referralOpportunitiesSection.title')}
          </KeyopsHeader3>
        </Stack>
        <ReferralOpportunitiesSection
          userData={userData}
          openReferral={openReferral}
          engagementData={engagementData}
        />
      </Box>
    </>
  );
};

export default EngagementSection;

import { BaseElementProps } from '@keyops-hcp/ui-components';
import {
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  useTheme,
} from '@mui/material';
import React from 'react';

export const EngagementCard = ({
  children,
  actionLabel,
  onClick,
  ...props
}: { onClick: () => void; actionLabel: string } & BaseElementProps) => {
  const theme = useTheme();
  return (
    <Card
      sx={{
        boxShadow: `none`,
        borderColor: theme.palette.keyops.blue.dark50,
        borderWidth: '0.5px',
        borderRadius: 2,
        height: '100%',
      }}
      variant="outlined"
    >
      <CardActionArea
        sx={{
          backgroundColor: theme.palette.keyops.white.main,
          px: props.sx?.px ?? 3,
          py: props.sx?.py ?? 3,
          height: '100%',
          display: 'flex',
          justiyContent: 'space-between',
          flexDirection: 'column',
        }}
        onClick={onClick}
        {...props}
      >
        <CardContent sx={{ width: '100%', p: 0 }}>{children}</CardContent>
        {actionLabel && (
          <CardActions
            sx={{
              width: '100%',
              justifyContent: 'flex-end',
              marginTop: 'auto',
              paddingBottom: 0,
            }}
          >
            <Button variant="outlined">{actionLabel}</Button>
          </CardActions>
        )}
      </CardActionArea>
    </Card>
  );
};

import React, { useState } from 'react';
import { useNavigate } from 'react-router';

import { NavItemType } from '../../../custom-hooks/useNavConstants';
import { useSurveyContext } from '../SurveyContext';
import ExitEngagementModal from './ExitEngagementModal';
import NavItem from '../../../components/NavItem';
import { useMediaQuery, useTheme } from '@mui/material';

interface CustomNavItemProps {
  item: NavItemType;
  isOpen: boolean;
  toggleDrawer?: (newOpen: boolean) => void;
}

const CustomNavItem: React.FC<CustomNavItemProps> = ({
  item,
  isOpen,
  toggleDrawer,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const navigate = useNavigate();
  const { surveyIframeRendered, saveAndResumeActive } = useSurveyContext();
  const [openModal, setOpenModal] = useState(false);

  const handlePathClick = () => (event: React.MouseEvent) => {
    if (item.path) {
      if (surveyIframeRendered && !saveAndResumeActive) {
        setOpenModal(true);
        return;
      }
      navigate(item.path);
      if (isMobile) {
        toggleDrawer(false);
      }
    } else if (item.action) {
      item.action(event);
      if (isMobile && toggleDrawer) {
        toggleDrawer(false);
      }
    }
  };

  return (
    <>
      <ExitEngagementModal
        openModal={openModal}
        setOpenModal={setOpenModal}
        navigateFn={() => navigate(item.path)}
      />
      <NavItem item={item} isOpen={isOpen} handlePathClick={handlePathClick} />
    </>
  );
};

export default CustomNavItem;

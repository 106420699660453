import React, { useEffect, useState } from 'react';
import { useOutletContext, useParams } from 'react-router';
import {
  AdBoardSectionsToggle,
  getCurrentSection,
  HCPAdBoardContextType,
  useAdBoardContext,
} from '@keyops-hcp/ui-components';
import { SectionDto } from '@keyops-hcp/dtos';
import { AdBoardLayoutOutletContext } from '../../higher-order-components/AdBoardLayout/AdBoardLayoutOutletContext';

export const AdBoardSections = () => {
  const { sectionId } = useParams();
  const { setCurrentSectionId } =
    useOutletContext<AdBoardLayoutOutletContext>();
  const { sectionsData: sections } =
    (useAdBoardContext() as HCPAdBoardContextType) || {};
  const [currentSection, setCurrentSection] = useState<SectionDto | undefined>(
    undefined
  );

  useEffect(() => {
    const section = getCurrentSection(sectionId, sections);
    if (section && section.id !== sectionId) {
      setCurrentSectionId(section.id);
    }
    setCurrentSection(section);
  }, [sectionId, sections]);
  return (
    <AdBoardSectionsToggle
      currentSection={currentSection}
      setCurrentSectionId={setCurrentSectionId}
    />
  );
};

import React from 'react';
import { Box, Stack, Toolbar, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { FiMenu } from 'react-icons/fi';
import { KeyOpsIcon, Notifications } from '@keyops-hcp/ui-components';

import { DASHBOARD } from '../../../utils/routes';

const MobileAppBar = ({
  toggleMobileSideNavDrawer,
}: {
  toggleMobileSideNavDrawer: (newOpen: boolean) => void;
}) => {
  const { palette } = useTheme();
  const navigate = useNavigate();

  return (
    <Toolbar data-testid="mobile-app-bar">
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        width="100%"
        margin={0}
        padding={0}
      >
        <Stack direction="row" spacing={2} alignItems="baseline" flexGrow={1}>
          <Box
            data-testid="drawer-menu"
            onClick={() => toggleMobileSideNavDrawer(true)}
          >
            <FiMenu fontSize={28} color={palette.keyops.blue.dark} />
          </Box>
          <Box width={33} height={33} onClick={() => navigate(DASHBOARD)}>
            <KeyOpsIcon />
          </Box>
        </Stack>
        <Notifications />
      </Stack>
    </Toolbar>
  );
};

export default MobileAppBar;

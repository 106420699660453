// External Dependencies
import React from 'react';
import {
  Tooltip,
  TooltipProps,
  Typography,
  tooltipClasses,
  useMediaQuery,
} from '@mui/material';
import { styled } from '@mui/system';
import { theme } from '@keyops-hcp/ui-components';

export const LogoImage = styled('img')({
  width: '160px',
});

export const MarketingImage = styled('img')({
  width: '90%',
  display: 'inline-block',
  alignSelf: 'center',
});

export const MarketingSubTitle1 = ({ children, ...props }) => (
  <Typography
    variant={'h5'}
    component={'h1'}
    fontWeight={600}
    color={theme.palette.keyops.black.main}
    {...props}
  >
    {children}
  </Typography>
);
export const MarketingSubTitle2 = ({ children, ...props }) => (
  <Typography
    variant={'body1'}
    color={theme.palette.keyops.black.main}
    {...props}
  >
    {children}
  </Typography>
);

export const FormTitle = ({ children, ...props }) => {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Typography
      variant={!isMobile ? 'h5' : 'h6'}
      component={'h1'}
      fontWeight={500}
      color={theme.palette.keyops.black.main}
    >
      {children}
    </Typography>
  );
};

export const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 250,
  },
});

import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router';
import { Box, Button, Toolbar, useTheme } from '@mui/material';
import { FiChevronLeft, FiUser } from 'react-icons/fi';

import { Notifications } from '@keyops-hcp/ui-components';

import KeyOpsLogo from '../../../images/KeyOpsLogo.svg';
import { DASHBOARD } from '../../../utils/routes';
import ProfileDrawer from './ProfileDrawer';

const DesktopAppBar = () => {
  const { palette } = useTheme();
  const navigate = useNavigate();
  const [isProfileDrawerOpen, setProfileDrawerOpen] = useState(false);
  const toggleProfileDrawer = useCallback(
    () => setProfileDrawerOpen((prev) => !prev),
    []
  );

  return (
    <Toolbar
      data-testid="desktop-app-bar"
      sx={{ justifyContent: 'space-between' }}
    >
      <img
        src={KeyOpsLogo}
        height={32}
        style={{ cursor: 'pointer' }}
        onClick={() => navigate(DASHBOARD)}
        alt="KeyOps-Logo-Full"
      />
      <Box display={'flex'}>
        <Notifications />
        <Button
          data-testid="profile-drawer-button"
          size="large"
          sx={{
            ml: 1.5,
            backgroundColor: 'transparent',
            borderRadius: 26,
            border: `1px solid ${palette.keyops.blue.dark}`,
            color: palette.keyops.blue.dark,
            '&:hover': {
              backgroundColor: palette.keyops.blue.dark,
              color: palette.keyops.background,
            },
          }}
          onClick={toggleProfileDrawer}
        >
          <FiChevronLeft style={{ fontSize: 20, marginLeft: '-8px' }} />
          <FiUser size={20} />
        </Button>
        <ProfileDrawer
          isProfileDrawerOpen={isProfileDrawerOpen}
          toggleProfileDrawer={toggleProfileDrawer}
        />
      </Box>
    </Toolbar>
  );
};

export default DesktopAppBar;

import { BaseElementProps } from '@keyops-hcp/ui-components';
import { Forum, ListAlt } from '@mui/icons-material';
import { Chip, Stack, Typography, useTheme } from '@mui/material';
import React, { Children } from 'react';
import { useTranslation } from 'react-i18next';

export const EngagementTopBar = ({
  type,
  children,
  ...props
}: {
  type: 'survey' | 'adBoard';
} & BaseElementProps) => {
  const theme = useTheme();
  const items = Children.toArray(children);
  const { t } = useTranslation();
  const iconStyle = {
    fontSize: { xs: '12px', sm: '12px', md: '20px' },
    marginRight: '8px',
    color: theme.palette.keyops.blue.dark,
  };
  return (
    <Stack
      spacing={4}
      direction="row"
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      {...props}
    >
      <Chip
        icon={
          type === 'survey' ? (
            <ListAlt sx={iconStyle} />
          ) : (
            <Forum sx={iconStyle} />
          )
        }
        label={t('general.' + type)}
        sx={{
          borderRadius: 2,
          backgroundColor: theme.palette.keyops.blue.light,
          color: theme.palette.keyops.blue.dark,
          fontWeight: 600,
          '& .MuiChip-icon': {
            color: theme.palette.keyops.blue.dark,
          },
        }}
      />
      {items.map((item, index) => (
        <Typography
          key={index}
          sx={{
            display: 'flex',
            alignItems: 'center',
            fontSize: { xs: '12px', sm: '12px', md: '14px' },
            color: theme.palette.keyops.gray[600],
          }}
        >
          {item}
        </Typography>
      ))}
    </Stack>
  );
};

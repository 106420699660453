// External Dependencies
import React, { useMemo } from 'react';
import { Grid, Typography } from '@mui/material/';
import { UserDto, EngagementData } from '@keyops-hcp/dtos';
import { useTranslation } from 'react-i18next';

// Internal Dependencies
import { ActiveSurveyCard } from './ActiveSurveyCard';
import {
  getActiveAdBoardInvitations,
  getActiveInvitations,
} from '../../../../../utils/functions/invitation-utils';
import { theme } from '@keyops-hcp/ui-components';
import { ActiveAdBoardCard } from './ActiveAdBoardCard';

type ActiveEngagementsSectionProps = {
  userData: UserDto;
  engagementData: EngagementData;
};

const ActiveEngagementsSection = ({
  engagementData,
}: ActiveEngagementsSectionProps) => {
  const { t } = useTranslation();

  // Functions
  const activeAdBoards = useMemo(
    () => getActiveAdBoardInvitations(engagementData.adBoardInvitations),
    [engagementData]
  );
  const activeSurveys = useMemo(
    () => getActiveInvitations(engagementData.surveyInvitations),
    [engagementData]
  );

  // Render
  const cards = activeAdBoards
    .map((invite) => (
      <ActiveAdBoardCard key={'adBoard' + invite.id} adBoardInvite={invite} />
    ))
    .concat(
      activeSurveys?.map(({ engagementId, engagement, state }) => {
        return (
          <ActiveSurveyCard
            key={'survey' + engagementId} //unique key for each child
            engagementId={engagementId}
            title={engagement.title}
            payout={engagement.payoutValue}
            duration={engagement.estimatedTime}
            state={state}
            points={engagement.rewardValue}
          />
        );
      })
    );

  return (
    <>
      {activeSurveys.length + activeAdBoards.length > 0 ? (
        <>
          <Grid columnSpacing={3} rowSpacing={2} container>
            {cards.map((card, index) => (
              <Grid key={index} item xs={12} md={12} lg={12} xl={6}>
                {card}
              </Grid>
            ))}
          </Grid>
        </>
      ) : (
        <Typography
          sx={{
            color: theme.palette.keyops.gray[600],
          }}
        >
          {t('dashboard.activeEngagementsSection.emptyMessage')}
        </Typography>
      )}
    </>
  );
};

export default ActiveEngagementsSection;

import React from 'react';
import { Card, Stack, Typography } from '@mui/material';
import { FiCalendar } from 'react-icons/fi';
import { useTranslation } from 'react-i18next';

import { theme } from '@keyops-hcp/ui-components';

export const TransactionCard = ({ reward }) => {
  const { amount, createdAt, type, notes } = reward;
  const { t } = useTranslation();

  return (
    <Card
      data-testid="TransactionCardId"
      elevation={0}
      sx={{
        borderRadius: '8px',
        padding: '16px',
        margin: '8px 0',
        border: `1px solid ${theme.palette.keyops.gray[200]}`,
      }}
    >
      <Stack
        direction={'row'}
        display="flex"
        justifyContent={'space-between'}
        sx={{ pb: '8px' }}
      >
        <Typography sx={{ fontSize: '14px', fontWeight: 400 }}>
          {type === 'other'
            ? notes
            : t(`rewards.pointsHistoryPanel.activityTypes.${type}`)}
        </Typography>
        <Typography
          align="right"
          sx={{
            fontWeight: 500,
            fontSize: '16px',
            color: theme.palette.keyops.blue.dark,
          }}
        >
          {amount}
        </Typography>
      </Stack>
      <Stack direction={'row'} display="flex" justifyContent={'space-between'}>
        <Stack
          direction={'row'}
          display="flex"
          justifyContent={'start'}
          alignItems={'center'}
        >
          <FiCalendar
            color={theme.palette.keyops.blue.dark}
            fontSize={'14px'}
          />
          <Typography sx={{ fontSize: '12px', fontWeight: 400, pl: '8px' }}>
            {t('rewards.pointsHistoryPanel.earnedDate', {
              earnedDate: createdAt,
            })}
          </Typography>
        </Stack>
        <Typography align="right" sx={{ fontWeight: 400, fontSize: '12px' }}>
          {t('rewards.pointsHistoryPanel.pointsEarnedCard')}
        </Typography>
      </Stack>
    </Card>
  );
};

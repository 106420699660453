// External Dependencies
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Typography, Box, useTheme, Stack } from '@mui/material/';
import { Schedule, Stars } from '@mui/icons-material';
import { EngagementTopBar } from './EngagementCardTopBar';
import { EngagementCard } from './EngagementCard';

type ActiveEngagementCardProps = {
  engagementId: number;
  title: string;
  payout: number;
  duration: number;
  state: string;
  points?: number;
};

export const ActiveSurveyCard = ({
  engagementId,
  title,
  payout,
  duration,
  state,
  points,
}: ActiveEngagementCardProps) => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const theme = useTheme();
  // 1. return Completed Engagement Row component
  return (
    <EngagementCard
      onClick={() => navigate(`/engagement/${engagementId}`)}
      actionLabel={
        state === 'in_progress'
          ? t('dashboard.activeEngagementsSection.resumeSurvey')
          : t('dashboard.activeEngagementsSection.startSurvey')
      }
    >
      <Stack spacing={2}>
        <EngagementTopBar type="survey">
          <Typography fontSize="14px" fontWeight={600}>
            {t('dashboard.activeEngagementsSection.earn')} ${payout}
          </Typography>
          <>
            <Schedule
              sx={{
                fontSize: 16,
                color: theme.palette.keyops.blue.dark,
                mr: 1,
              }}
            />
            {`${duration ? duration : ''}${
              duration
                ? t('dashboard.activeEngagementsSection.minutes')
                : t('dashboard.activeEngagementsSection.defaultDuration')
            }`}
          </>
          {points && (
            <>
              <Stars
                sx={{
                  fontSize: 16,
                  mr: 1,
                  color: theme.palette.keyops.blue.dark,
                }}
              />{' '}
              {points}
            </>
          )}
        </EngagementTopBar>
        {/* Engagement title */}
        <Box minHeight="48px">
          <Typography
            variant="body1"
            color={theme.palette.keyops.black.main}
            sx={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: '-webkit-box',
              WebkitLineClamp: 2,
              WebkitBoxOrient: 'vertical',
            }}
          >
            {title}
          </Typography>
        </Box>
      </Stack>
    </EngagementCard>
  );
};

import React, { useMemo } from 'react';
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import { t } from 'i18next';
import { HTMLOutput, KeyopsHeader3 } from '@keyops-hcp/ui-components';
import { HcpTypeOptions } from '../../../utils/enum-display';
import { formatSpecialties } from '../../../utils/functions/eligibility.utils';
import { Trans } from 'react-i18next';

const EngagementInfoDisplay = ({ engagement }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const allowedSpecialties = useMemo(() => {
    if (
      !engagement ||
      !engagement.allowedSpecialties ||
      engagement.hcpType !== 'physician'
    )
      return [];

    return formatSpecialties(engagement.allowedSpecialties);
  }, [engagement]);

  if (engagement.type === 'survey') {
    const allowedHcpType = HcpTypeOptions.getDisplay(engagement.hcpType);
    //TODO: might want to pull the different engagement type displays into separate components.
    return (
      <Box mt={isMobile && 2} mb={isMobile && 0}>
        <KeyopsHeader3>{t('onboarding.engagementDetails.title')}</KeyopsHeader3>
        <Typography fontSize={isMobile && 15}>
          <p>
            {allowedSpecialties.length > 0 && allowedHcpType
              ? t(
                  `onboarding.engagementDetails.eligibilityMessageWithSpecialties`,
                  {
                    requiredHCPType: allowedHcpType,
                    requiredSpecialties: allowedSpecialties,
                  }
                )
              : t(
                  `onboarding.engagementDetails.eligibilityMessageNoSpecialties`,
                  {
                    requiredHCPType: allowedHcpType,
                  }
                )}
          </p>
          {engagement.hasOtherEligibilityCriteria && (
            <b>
              <p>
                {t(
                  `onboarding.engagementDetails.otherEligibilityCriteriaIntro`
                )}
              </p>
              <HTMLOutput html={engagement.otherEligibilityCriteria} />
            </b>
          )}
          <p data-testid="payout-info">
            {t(`onboarding.engagementDetails.earn`, {
              payout: engagement.payoutValue,
              engagementTitle: engagement.title,
            })}
          </p>
          <p>{t(`onboarding.engagementDetails.notEligible`)}</p>
        </Typography>
      </Box>
    );
  }
  if (engagement.type === 'adBoard') {
    return (
      <Box mt={isMobile && 2} mb={isMobile && 0}>
        <KeyopsHeader3>{t('onboarding.engagementDetails.title')}</KeyopsHeader3>
        <Typography fontSize={isMobile && 15}>
          <p data-testid="payout-info">
            <Trans
              i18nKey="onboarding.engagementDetails.adBoardMessage"
              values={{
                payout: engagement.payoutValue,
                engagementTitle: engagement.title,
              }}
            />
          </p>
        </Typography>
      </Box>
    );
  }
};

export default EngagementInfoDisplay;

import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import enTranslation from './en.json';
import { enComponentsTranslation } from '@keyops-hcp/ui-components';

// TODO: add 'fr' when ready to support French
const options = {
  fallbackLng: 'en',
  supportedLngs: ['en'],
  debug: false,
  resources: {
    en: {
      translation: enTranslation,
      components: enComponentsTranslation,
    },
  },
  ns: ['translation', 'components'],
  fallbackNS: 'components', //we fall back to the resource bundles in the ui-components package
  interpolation: {
    escapeValue: false, // React already does escaping
    format: function (value: string, format: string) {
      if (format === 'capitalize') {
        return value.charAt(0).toUpperCase() + value.slice(1);
      }
      return value;
    },
  },
  detection: {
    order: ['navigator'], // Order and from where user language should be detected
  },
};

// To disable a warning on import i18next
// eslint-disable-next-line import/no-named-as-default-member
i18next
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init(options)
  .then(() => {
    console.log('Detected language:', i18next.language);
  });

export default i18next;

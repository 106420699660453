// External Dependencies
import React from 'react';
import { Typography, Box, useTheme, Stack } from '@mui/material/';
import { useTranslation } from 'react-i18next';
import { EngagementCard } from './EngagementCard';
import { EngagementTopBar } from './EngagementCardTopBar';
import { Stars } from '@mui/icons-material';

type ReferralOpportunitiesCardProps = {
  engagementId: number;
  title: string;
  payout: number;
  openReferral: (number) => void;
  points?: number;
};

const ReferralOpportunitiesCard = ({
  engagementId,
  title,
  payout,
  openReferral,
  points,
}: ReferralOpportunitiesCardProps) => {
  const theme = useTheme();
  const { t } = useTranslation();

  // 1. return Completed Engagement Row component
  return (
    <EngagementCard
      actionLabel={t('dashboard.activeEngagementsSection.referColleague')}
      onClick={() => openReferral(engagementId)}
      sx={{ px: 3, py: 1 }}
    >
      <Stack spacing={1}>
        <EngagementTopBar type="survey">
          <Typography fontSize="14px" fontWeight={600}>
            {t('dashboard.activeEngagementsSection.earn')} ${payout}
          </Typography>
          {points && (
            <>
              <Stars
                sx={{
                  fontSize: 16,
                  mr: 1,
                  color: theme.palette.keyops.blue.dark,
                }}
              />{' '}
              {points}
            </>
          )}
        </EngagementTopBar>
        {/* Engagement title */}
        <Box>
          <Typography
            variant="body1"
            color={theme.palette.keyops.black.main}
            sx={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: '-webkit-box',
              WebkitLineClamp: 2,
              WebkitBoxOrient: 'vertical',
            }}
          >
            {title}
          </Typography>
        </Box>
      </Stack>
    </EngagementCard>
  );
};

export default ReferralOpportunitiesCard;

import React from 'react';
import { AppBar, useMediaQuery, useTheme } from '@mui/material';

import MobileAppBar from './MobileAppBar';
import DesktopAppBar from './DesktopAppBar';

const CustomAppBar = ({
  toggleMobileSideNavDrawer,
}: {
  toggleMobileSideNavDrawer: (newOpen: boolean) => void;
}) => {
  const { breakpoints, palette } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down('sm'));

  return (
    <AppBar
      position={'fixed'}
      component="nav"
      sx={{
        bgcolor: !isMobile
          ? palette.keyops.background
          : palette.keyops.white.main,
        boxShadow: isMobile ? '0px 1px 2px 0px rgba(0, 0, 0, 0.06)' : 'none',
      }}
      data-testid="custom-app-bar"
    >
      {!isMobile && <DesktopAppBar />}
      {isMobile && (
        <MobileAppBar toggleMobileSideNavDrawer={toggleMobileSideNavDrawer} />
      )}
    </AppBar>
  );
};

export default CustomAppBar;

import React, { MouseEvent, useState } from 'react';
import {
  IconButton,
  InputAdornment,
  Box,
  Button,
  Divider,
  Link,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { VisibilityOutlined, VisibilityOffOutlined } from '@mui/icons-material';
import { useWatch } from 'react-hook-form';

import { FORM_FIELDS } from './form-constants';
import { LOGIN } from '../../../utils/routes';
import GoogleSignUp from '../components/GoogleSignUp';
import KeyOpsTextField from '../components/TextField';
import {
  PRIVACY_POLICY_LINK,
  TERMS_OF_USE_LINK,
} from '../../../utils/constants';
import { LogoImage } from '../styles';
import Logo from '../../../images/KeyOpsLogo.svg';
import ReferralMobileMarketing from '../components/ReferralMobileMarketing';
import { KeyopsHeader3, KeyopsSubTitle1 } from '@keyops-hcp/ui-components';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const formFields = FORM_FIELDS.landingPage;

const LandingForm = ({
  control,
  register,
  isValid,
  setValue,
  errors,
  onSubmit,
  attachedEngagement,
  mobileMarketingDisplayed,
  setMobileMarketingDisplayed,
}) => {
  const { t } = useTranslation();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [searchParams] = useSearchParams();
  const referralId = searchParams.get('referralId');

  const isGoogleOnboarding = !!useWatch({
    control,
    name: 'continue-with-google',
  });
  const passwordValue = useWatch({
    control,
    name: 'password',
  });

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  return (
    <Box
      display={'flex'}
      flexDirection={'column'}
      alignItems={'center'}
      justifyContent={'space-between'}
      height={'100%'}
      width={'90%'}
      mx={'auto'}
      gap={2}
    >
      {!isMobile ? (
        <Typography
          variant={'body1'}
          color={theme.palette.keyops.black.main}
          alignSelf={'flex-end'}
          pr={4}
        >
          {t(`onboarding.forms.landingPage.haveAccount`)}{' '}
          <Link
            href={referralId ? `${LOGIN}/?referralId=${referralId}` : LOGIN}
            underline="none"
          >
            {t(`onboarding.forms.landingPage.signIn`)}{' '}
          </Link>
        </Typography>
      ) : (
        <Box display={'flex'} justifyContent={'center'}>
          <LogoImage src={Logo} />
        </Box>
      )}
      {isMobile && !mobileMarketingDisplayed && attachedEngagement ? (
        <ReferralMobileMarketing
          setMobileMarketingDisplayed={setMobileMarketingDisplayed}
          attachedEngagement={attachedEngagement}
        />
      ) : (
        <>
          <Box
            display={'flex'}
            flexDirection={'column'}
            textAlign={'center'}
            maxWidth={600}
            minWidth={!isMobile && 400}
          >
            <KeyopsHeader3 mt={isMobile && 4}>
              {t(`onboarding.forms.landingPage.createAccountTitle`)}
            </KeyopsHeader3>
            <form
              onSubmit={onSubmit}
              style={{
                width: '100%',
                maxWidth: '400px',
                alignSelf: 'center',
                marginTop: '32px',
              }}
            >
              <Stack spacing={4}>
                <GoogleSignUp setValue={setValue} onSubmit={onSubmit} />
                <Divider flexItem />
                <KeyOpsTextField
                  fieldName={formFields.email.name}
                  placeholder={!isGoogleOnboarding && formFields.email.label}
                  rules={{
                    required: t(`onboarding.forms.fieldErrors.required`, {
                      field: formFields.email.label,
                    }),
                  }}
                  disabled={isGoogleOnboarding}
                  inputProps={{ 'data-testid': formFields.email.name }}
                  register={register}
                  errors={errors}
                />
                {!isGoogleOnboarding && (
                  <KeyOpsTextField
                    fieldName={formFields.password.name}
                    placeholder={formFields.password.label}
                    rules={
                      !isGoogleOnboarding && {
                        required: t(`onboarding.forms.fieldErrors.required`, {
                          field: formFields.password.label,
                        }),
                      }
                    }
                    type={showPassword ? 'text' : 'password'}
                    inputProps={{ 'data-testid': formFields.password.name }}
                    InputProps={{
                      endAdornment: !!passwordValue && (
                        <InputAdornment position="end">
                          <IconButton
                            data-testid="toggle-password-visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? (
                              <VisibilityOffOutlined />
                            ) : (
                              <VisibilityOutlined />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    register={register}
                    errors={errors}
                  />
                )}
                <Button
                  type="submit"
                  variant="contained"
                  disabled={!isValid}
                  sx={{
                    textTransform: 'inherit',
                    width: '100%',
                    '&:disabled': {
                      backgroundColor: theme.palette.keyops.blue.main90,
                      color: theme.palette.keyops.white.main,
                    },
                  }}
                  disableElevation
                >
                  {t(`onboarding.forms.landingPage.createAccount`)}
                </Button>
              </Stack>
            </form>
          </Box>
          <Typography
            variant={'body2'}
            color={theme.palette.keyops.black.main}
            textAlign={'center'}
            width={'95%'}
            textOverflow={'ellipsis'}
          >
            {t(`onboarding.forms.landingPage.byContinuing`)}{' '}
            <Link href={TERMS_OF_USE_LINK} target="_blank" underline="none">
              {t(`onboarding.forms.landingPage.termsOfService`)}{' '}
            </Link>
            {t(`general.and`)}{' '}
            <Link href={PRIVACY_POLICY_LINK} target="_blank" underline="none">
              {t(`onboarding.forms.landingPage.privacyPolicy`)}{' '}
            </Link>
            {'.'}
          </Typography>
          {isMobile && (
            <KeyopsSubTitle1
              color={theme.palette.keyops.black.main}
              textAlign={'center'}
              mt={2}
            >
              {t(`onboarding.forms.landingPage.haveAccount`)}{' '}
              <Link href={LOGIN} underline="none">
                {t(`onboarding.forms.landingPage.signIn`)}{' '}
              </Link>
            </KeyopsSubTitle1>
          )}
        </>
      )}
    </Box>
  );
};

export default LandingForm;

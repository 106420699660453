import React, { useState } from 'react';
import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useMatch } from 'react-router';

import { NavItemType } from '../../custom-hooks/useNavConstants';

interface NavItemProps {
  item: NavItemType;
  isOpen: boolean;
  handlePathClick: () => (event: React.MouseEvent) => void;
}

const NavItem: React.FC<NavItemProps> = ({ item, isOpen, handlePathClick }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const match = useMatch(`${item.path}/*`);

  const [hovered, setHovered] = useState(false);

  return (
    <ListItem
      key={item.name}
      sx={{
        display: 'block',
        py: 0.5,
      }}
    >
      <ListItemButton
        onClick={handlePathClick()}
        onMouseEnter={() => setHovered(!isMobile)}
        onMouseLeave={() => setHovered(false)}
        sx={{
          minHeight: 48,
          justifyContent: 'center',
          borderRadius: 2,
          bgcolor: !!match && theme.palette.keyops.blue.light,
          '&:hover': {
            bgcolor: theme.palette.keyops.blue.dark,
            cursor: 'pointer',
          },
          pr: { xs: 8 },
        }}
      >
        <ListItemIcon
          data-testid="nav-item-icon"
          sx={{
            minWidth: 0,
            mr: isOpen && 3,
            justifyContent: 'center',
          }}
        >
          <item.icon
            color={
              hovered
                ? theme.palette.keyops.white.main
                : theme.palette.keyops.blue.dark
            }
            fontSize={20}
          />
        </ListItemIcon>
        {isOpen && (
          <ListItemText
            primary={item.name}
            sx={{
              color: hovered
                ? theme.palette.keyops.white.main
                : theme.palette.keyops.blue.dark,
            }}
          />
        )}
      </ListItemButton>
    </ListItem>
  );
};

export default NavItem;
